// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import store from './store';
import config from './config/mixin';
import filter from './config/filter';
import VueResource from "vue-resource"
import cTable from './views/base/Table.vue'
import Note from './views/admin/Customer/Customer/Detail/Note'
import MiniTable from './views/base/MiniTable.vue'
import Loading from 'vue-loading-overlay';
import JsonExcel from 'vue-json-excel'
import Multiselect from 'vue-multiselect'
import money from 'v-money'
import TreeView from "vue-json-tree-view"
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import VueHtmlToPaper from "vue-html-to-paper";

// ...
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    '/css/print.css'
  ]
};

Vue.use(VueHtmlToPaper, options);
Vue.use(datePicker);
Vue.use(TreeView)
Vue.use(BootstrapVue)
Vue.use(VueResource)
Vue.use(money, {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: ' ₮',
    precision: 2
})


Vue.component('loading', Loading)
Vue.component('c-table', cTable)
Vue.component('mini-table', MiniTable)

Vue.component('Note', Note)
Vue.component('multiselect', Multiselect)
Vue.component('downloadExcel', JsonExcel)

router.duplicationErroringPush = router.push;
router.push = async function (location) {
  return router.duplicationErroringPush(location).catch((error) => {
    if (error.name === 'NavigationDuplicated') {
      return this.currentRoute;
    }
    throw error;
  });
};
new Vue({
  el: '#app',
  router,
  store,
  config,
  filter,
  template: '<App/>',
  components: {
    App
  }
})
