<template>
  <b-col sm="4">
    <b-form-group >
      <div v-if="type == 'date'">
        <b-input-group>
          <b-form-input placeholder="эхлэх" type="date"></b-form-input>
          <b-form-input placeholder="дуусах" type="date" ></b-form-input>
        </b-input-group>
      </div>
      <div v-else-if="type == 'amount'">
        <b-input-group>
          <money class="form-control text-right" placeholder="доод"></money>
          <money class="form-control text-right" placeholder="дээд"></money>
        </b-input-group>
      </div>
      <div v-else-if="type == 'boolean'">
        <b-form-checkbox value="true">{{ label }}</b-form-checkbox>
      </div>
      <div v-else>
        <b-form-input :placeholder="this.label" ></b-form-input>
      </div>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'TableFilter',
  props: {
    index: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    key: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data: function () {
    return {
        
    }
  },
  methods: {
    
  }
}
</script>
