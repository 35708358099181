<template>
  <b-row>
    <b-col class="col-md-3" v-if="filterShow">
      <b-card header="Шүүх">
        <b-form @submit.stop.prevent="submitSearch" @reset="resetSearch">
          <b-row>
            <b-col :key="fi" v-for="(fil, fi) in this.filters" sm="12">
              <b-form-group
                v-if="fil.filter_type == 'amount'"
                :label="fil.label"
                :label-for="fil.key"
              >
                <b-input-group>
                  <money
                    class="form-control text-right"
                    placeholder="доод"
                    v-model="fil.value.min"
                    :id="fil.key"
                  ></money>
                  <money
                    class="form-control text-right"
                    placeholder="дээд"
                    v-model="fil.value.max"
                  ></money>
                </b-input-group>
              </b-form-group>
              <b-form-group
                v-else-if="fil.filter_type == 'date'"
                :label="fil.label"
                :label-for="fil.key"
              >
                <date-range-picker
                  class="w-100"
                  :locale-data="dateRange().LOCALE"
                  opens="right"
                  :autoApply="true"
                  v-model="fil.value"
                  :ranges="dateRange().RANGES"
                ></date-range-picker>
              </b-form-group>
              <b-form-group
                v-else-if="fil.filter_type == 'boolean'"
                :label="fil.label"
                :label-for="fil.key"
              >
                <b-form-select
                  v-model="fil.value"
                  :options="[
                    { value: null, text: 'Сонгоно уу' },
                    { value: true, text: 'Тийм' },
                    { value: false, text: 'Үгүй' },
                  ]"
                  :id="fil.key"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-else-if="fil.filter_type == 'number'"
                :label="fil.label"
                :label-for="fil.key"
              >
                <b-input-group>
                  <b-form-input
                    type="number"
                    placeholder="доод"
                    v-model="fil.value.min"
                    :id="fil.key"
                  ></b-form-input>
                  <b-form-input
                    type="number"
                    placeholder="дээд"
                    v-model="fil.value.max"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group v-else :label="fil.label" :label-for="fil.key">
                <b-form-input
                  v-model="fil.value"
                  placeholder="Бичнэ үү"
                  :id="fil.key"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="pull-right">
            <b-button type="reset" variant="danger" class="mr-2"
              >Цэвэрлэх</b-button
            >
            <b-button type="submit" variant="primary">Шүүх</b-button>
          </div>
        </b-form>
      </b-card>
    </b-col>
    <b-col :class="{ 'col-md-9': filterShow, 'col-md-12': !filterShow }">
      <b-card footer-tag="footer">
        <loading
          :active.sync="isLoadingData"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <div class="mb-3">
          <b-button
            variant="danger"
            class="pull-right ml-3"
            @click="selectedFunction"
            v-if="selectable"
            :disabled="selected.length == 0"
          >
            <i :class="this.selectedButton.icon"></i>
            {{ this.selectedButton.text }} ({{ selected.length }})
          </b-button>
          <download-excel
            class="btn btn-primary pull-right ml-3"
            :fetch="fetchData"
            :fields="download.header"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            :name="filename + '.xls'"
            v-if="this.export && items != undefined && items.length > 0"
          >
            <b-spinner small type="grow" v-show="download.loading"></b-spinner>
            <span v-show="download.loading" class="ml-1">Уншиж байна</span>
            <i class="fa fa-download" v-show="!download.loading"></i>
            <span v-show="!download.loading" class="ml-1">Excel татах</span>
          </download-excel>
          <b-button
            variant="primary"
            class="pull-right ml-3"
            :to="{ name: edit_route, params: edit_params }"
            v-if="edit_has && !edit_only && checkPermission(edit_route)"
          >
            <i class="fa fa-plus"></i> Шинэ
          </b-button>
          <div class="pull-right">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100, 250, 500]"
              v-if="items != undefined && items.length > 0"
            >
            </b-form-select>
          </div>
          <b-row class="search-form">
            <b-col
              class="col-md-3"
              v-if="checkSearchFields('loanCreatedUser')"
              :class="{ 'pl-0': items != undefined && items.length > 0 }"
            >
              <multiselect
                v-model="selected_loan_created_user"
                :options="loan_created_users"
                :searchable="true"
                placeholder="Зээлийн ажилтан"
                :close-on-select="true"
                :option-height="10"
                track-by="id"
              >
                <template slot="singleLabel" slot-scope="props">
                  @{{ props.option.firstname }}
                </template>
                <template slot="option" slot-scope="props">
                  @{{ props.option.firstname }}
                </template>
              </multiselect>
            </b-col>
            <b-col class="col-md-3" v-if="checkSearchFields('merchant')">
              <multiselect
                v-model="selected_merchant"
                :options="merchants"
                :searchable="true"
                placeholder="Merchant"
                :close-on-select="true"
                :option-height="10"
                track-by="id"
              >
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.code + " | " + props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.code + " | " + props.option.name }}
                </template>
              </multiselect>
            </b-col>

            <b-col
              class="col-md-4"
              :class="{ 'pl-0': items != undefined && items.length > 0 }"
            >
              <b-form @submit.stop.prevent="submitSearch">
                <b-input-group>
                  <b-input-group-prepend is-text
                    ><i class="fa fa-search"></i
                  ></b-input-group-prepend>
                  <b-form-input
                    v-model="search"
                    placeholder="Хайх"
                  ></b-form-input>
                  <b-input-group-append
                    ><b-button variant="primary" type="submit"
                      >Хайх</b-button
                    ></b-input-group-append
                  >
                  <b-input-group-append
                    ><b-button variant="secondary" @click="loadData"
                      ><i class="fa fa-refresh"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col>
            <div
              class="table-responsive"
              v-if="items != undefined && items.length > 0"
            >
              <b-table
                id="filter-table"
                stacked="md"
                @sort-changed="sortingChanged"
                :dark="dark"
                :hover="hover"
                :striped="striped"
                :bordered="bordered"
                :small="true"
                :fixed="false"
                responsive="sm"
                :items="items"
                :fields="captions"
                no-local-sorting
              >
                <template v-slot:head(checkbox)="data">
                  <b-form-checkbox
                    v-model="selectAll"
                    :index="data.index"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(checkbox)="data">
                  <b-form-checkbox
                    type="checkbox"
                    :value="data.item"
                    v-model="selected"
                  ></b-form-checkbox>
                </template>
                <template v-slot:cell(index)="data">
                  {{ data.index + pagination.from }}
                </template>
                <template v-slot:cell(parent)="data">
                  {{
                    data.item.hasOwnProperty("parent")
                      ? data.item.parent.name
                      : ""
                  }}
                </template>
                <template v-slot:cell(nicename)="data">
                  {{
                    data.item.lastname.substr(0, 1) + "." + data.item.firstname
                  }}
                </template>
                <template v-slot:cell(overdueDay)="data">
                  <strong class="text-danger" v-if="data.item.overdueDay">
                    {{ data.item.overdueDay }} өдөр</strong
                  >
                </template>
                <template v-slot:cell(contractNumber)="data">
                  {{ data.item.contractNumberStartStr }} -
                  {{ data.item.contractNumberEndStr }}
                </template>
                <template v-slot:cell(contractFile)="data">
                  <a
                    :href="
                      'https://admin.numur.mn/contract/' + data.item.fileName
                    "
                    >{{ data.item.fileName }}</a
                  >
                </template>
                <template v-slot:cell(actions)="data">
                  <div>
                    <b-button-group>
                      <b-button
                        size="sm"
                        :variant="item.variant"
                        :key="_index"
                        v-for="(item, _index) in data.field.buttons"
                        @click="
                          item.hasOwnProperty('callback')
                            ? item.callback(data.item.customerId)
                            : null
                        "
                        :to="{
                          name: item.route,
                          params: { id: data.item[item.route_param] },
                        }"
                        v-show="
                          (!item.hasOwnProperty('permission') ||
                            (item.hasOwnProperty('permission') &&
                              checkPermission(item.permission))) &&
                            (!item.hasOwnProperty('check_show') ||
                              (item.hasOwnProperty('check_show') &&
                                data.item[item.check_show.field] ==
                                  item.check_show.value))
                        "
                        v-b-tooltip.hover
                        :title="item.hasOwnProperty('title') ? item.title : ''"
                      >
                        <i :class="item.icon"></i>
                        {{ item.label }}
                      </b-button>

                      <b-button
                        size="sm"
                        variant="outline-primary"
                        :to="{ name: edit_route, params: { id: data.item.id } }"
                        v-if="edit_has && checkPermission(edit_route)"
                        class="mr-1"
                        v-b-tooltip.hover
                        title="Засах"
                      >
                        <i class="fa fa-pencil"></i>
                      </b-button>
                      <b-button
                        size="sm"
                        variant="outline-danger"
                        @click="remove(data.item.id)"
                        v-if="delete_has"
                        v-b-tooltip.hover
                        title="Устгах"
                      >
                        <i class="fa fa-remove"></i>
                      </b-button>
                    </b-button-group>
                  </div>
                </template>
                <template v-slot:cell(roleName)="data">
                  <b-badge
                    variant="primary"
                    :key="_index"
                    v-for="(label, _index) in data.item.roleName.split(',')"
                    class="mr-1"
                    >{{ label }}</b-badge
                  >
                </template>
                <template v-slot:cell(interestList)="data">
                  <ul class="list-unstyled mb-0">
                    <li
                      :key="index"
                      v-for="(item, index) in data.item.interestList"
                    >
                      {{ item.term }} сар - {{ item.interest }}%
                    </li>
                  </ul>
                </template>
                <template v-slot:cell(feePercent)="data">
                  {{ data.item.feePercent }}%
                </template>
                <template v-slot:cell(icon)="data">
                  <i :class="data.item.icon"></i>
                </template>
                <template v-slot:cell(isActive)="data">
                  <b-badge variant="success" v-if="data.item.isActive"
                    >Тийм</b-badge
                  >
                  <b-badge variant="danger" v-else>Үгүй</b-badge>
                </template>
                <template v-slot:cell(term)="data">
                  {{ data.item.term }}
                  <span
                    v-if="
                      data.item.hasOwnProperty('noInterestMonth') &&
                        data.item.noInterestMonth > 0
                    "
                    class="text-danger"
                    >({{ data.item.noInterestMonth }})</span
                  >
                </template>
                <template v-slot:cell(payAccountNumberWithBank)="data">
                  {{ data.item.payBank }}<br />
                  <small class="text-muted">{{
                    data.item.payAccountNumber
                  }}</small>
                </template>

                <template v-slot:cell(contractedDtl)="data">
                  <span>{{ data.item.contractedUserEmail }}</span
                  ><br />
                  <span>{{ data.item.contractedNumber }}</span>
                </template>

                <template v-slot:cell(isContract)="data">
                  <b-badge variant="success" v-if="data.item.isContract"
                    >Тийм</b-badge
                  >
                  <b-badge variant="danger" v-else>Үгүй</b-badge>
                </template>
                <template v-slot:cell(isIncomeInfo)="data">
                  <b-badge variant="success" v-if="data.item.isIncomeInfo"
                    >Тийм</b-badge
                  >
                  <b-badge variant="danger" v-else>Үгүй</b-badge>
                </template>
                <template v-slot:cell(isPaidNd)="data">
                  <b-badge variant="success" v-if="data.item.isPaidNd"
                    >Тийм</b-badge
                  >
                  <b-badge
                    variant="danger"
                    v-else-if="data.item.isPaidNd != null"
                    >Үгүй</b-badge
                  >
                </template>
                <template v-slot:cell(status)="data">
                  <b-badge
                    variant="success"
                    v-if="data.item.status == 'SUCCESS'"
                    >{{ data.item.status }}</b-badge
                  >
                  <b-badge
                    variant="primary"
                    v-else-if="data.item.status == 'NEW'"
                    >{{ data.item.status }}</b-badge
                  >
                  <b-badge variant="danger" v-else>{{
                    data.item.status
                  }}</b-badge>
                </template>
                <template v-slot:cell(sysUserName)="data">
                  <strong class="text-primary">
                    {{ data.item.sysUserName }}
                  </strong>
                </template>
                <template v-slot:cell(fbToken)="data">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="getLastWorkData(data.item)"
                    style="width: 50px;"
                    ><i class="fa fa-eye"></i>
                    {{ data.item.fbToken ? "FB" : "" }}</b-button
                  >
                </template>
                <template v-slot:cell(file)="data">
                  <b-button
                    variant="primary"
                    size="sm"
                    @click="getOverdueJudgeHTML(data.item)"
                    style="width: 50px;"
                    v-if="
                      checkPermission(
                        'admin.loan-repayment-overdue.create-statement-judge'
                      )
                    "
                    ><i class="fa fa-eye"></i
                  ></b-button>
                  <b-button
                    class="ml-1"
                    variant="primary"
                    size="sm"
                    @click="getOrderJudgeModal(data.item)"
                    v-if="
                      checkPermission(
                        'admin.loan-repayment-overdue.create-statement-judge'
                      )
                    "
                    >нэхэмжлэх</b-button
                  >
                  <b-button
                    class="ml-1"
                    variant="primary"
                    size="sm"
                    @click="getAccreditedJudgeModal(data.item)"
                    v-if="
                      checkPermission(
                        'admin.loan-repayment-overdue.create-statement-judge'
                      )
                    "
                    >итгэмжлэл</b-button
                  >
                  <b-button
                    class="ml-1"
                    variant="primary"
                    size="sm"
                    @click="getCalculationJudgeModal(data.item)"
                    v-if="
                      checkPermission(
                        'admin.loan-repayment-overdue.create-statement-judge'
                      )
                    "
                    >тооцоолол</b-button
                  >
                </template>
                <template v-slot:cell(lastNd)="data">
                  <span v-if="data.item.lastNdEmployerName">
                    {{ data.item.lastNdEmployerName }} /{{
                      data.item.lastNdEmployerDistrict
                    }}/<br />
                    <small class="text-muted"
                      >{{ data.item.lastNdYear }} оны
                      {{ data.item.lastNdMonth }} cар</small
                    >
                  </span>
                </template>

                <template v-slot:cell(typeCode)="data">
                  {{ $store.getters.loan_type[data.item.typeCode] }}
                </template>

                <template v-slot:cell(loanTypeCodeList)="data">
                  <b-badge
                    :key="index"
                    v-for="(item, index) in data.item.loanTypeCodeList"
                    variant="info mr-1"
                    >{{ $store.getters.loan_type[item] }}</b-badge
                  >
                </template>

                <template v-slot:cell(lastLoanClass)="data">
                  <b-badge
                    variant="success"
                    class="mr-1"
                    v-if="data.item.cibIsClosed"
                    >хаагдсан</b-badge
                  >
                  <b-badge variant="warning">{{
                    data.item.lastLoanClassName
                  }}</b-badge>
                  <small class="text-muted pull-right mt-1">{{
                    data.item.lastLoanClassDate
                  }}</small>
                </template>

                <template v-slot:cell(merchantNameWithType)="data">
                  {{ data.item.merchantName }}<br />
                  <small variant="info" class="text-muted">{{
                    $store.getters.loan_type[data.item.typeCode]
                  }}</small>
                </template>
              </b-table>
              <div slot="footer" class="mb-0 mt-3">
                <nav
                  class="pull-right"
                  v-if="pagination.total > query.per_page"
                >
                  <b-pagination
                    class="mb-0"
                    :total-rows="pagination.total"
                    :per-page="query.per_page"
                    v-model="query.page"
                  />
                </nav>
                <p class="mb-0">
                  {{ pagination.total }} өгөгдлөөс
                  {{ pagination.to ? pagination.from : 0 }} -
                  {{ pagination.to }} өгөгдөл харагдаж байна.
                </p>
              </div>
            </div>

            <b-alert variant="warning" v-else show class="mb-0">
              Мэдээлэл байхгүй байна
            </b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-modal
        ref="fb-modal"
        hide-footer
        :title="modalData.nicename + ' - ' + modalData.phone"
      >
        <div class="d-block">
          <div class="text-center" v-if="modalData.fb_id != ''">
            <b-img
              alt="placeholder"
              :src="
                'http://graph.facebook.com/' +
                  modalData.fb_id +
                  '/picture?type=large'
              "
            ></b-img>
            <h2 class="mt-0">{{ modalData.fb_name }}</h2>
            <hr />
          </div>
          <b-media v-if="modalData.work.name != ''">
            <p class="mt-0 mb-0" style="font-size:16px;">
              НДШ төлсөн сүүлийн байгуулга
            </p>
            <h3>
              <strong>{{ modalData.work.name }}</strong> -
              {{ modalData.work.year }} оны {{ modalData.work.month }} сар
            </h3>
          </b-media>
        </div>
      </b-modal>

      <b-modal ref="judge-modal" hide-footer title="Шүүхийн нэхэмжлэх">
        <div class="d-block">
          <div class="text-left" v-if="modalData.fb_id != ''">
            <div class="form-group">
              <label for="judge_select">Нэхэмжлэх гаргах шүүх</label>
              <select
                name=""
                id="judge_select"
                class="form-control"
                v-model="selected_judge"
              >
                <option :value="null" selected disabled>Сонгоно уу</option>
                <option
                  v-for="(item, index) in judge_list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <button
              class="btn btn-primary text-center"
              @click="getOrderJudgeHTML"
            >
              Хэвлэх
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="judge-accredited-modal"
        hide-footer
        title="Шүүхийн итгэмжлэл"
      >
        <div class="d-block">
          <div class="text-left">
            <div class="form-group">
              <label for="judge_select">Итгэмжлэл гаргах шүүх</label>
              <select
                name=""
                id="judge_select"
                class="form-control"
                v-model="selected_accredited_judge"
              >
                <option :value="null" selected disabled>Сонгоно уу</option>
                <option
                  v-for="(item, index) in judge_list"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <hr />
              <b-form-group
                id="input-group-lastname"
                label="Итгэмжлэх ажилтан:"
                label-for="input-lastname"
              >
                <b-form-textarea
                  id="input-lastname"
                  v-model="accredited_user"
                  type="text"
                  placeholder="Бичнэ үү"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <button
              class="btn btn-primary text-center"
              @click="getAccreditedJudgeHTML"
            >
              Хэвлэх
            </button>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="judge-calculation-modal"
        hide-footer
        title="Цахим зээлийн тооцоолол"
      >
        <div class="d-block">
          <div class="text-left">
            <div class="form-group">
              <b-form-group
                id="input-group-lastname"
                label="Тооцоолол гаргасан ажилтан:"
                label-for="input-lastname"
              >
                <b-input
                  class="mb-3"
                  v-model="calculation_position"
                  placeholder="Албан тушаал"
                ></b-input>
                <b-input v-model="calculation_name" placeholder="Нэр"></b-input>
              </b-form-group>
              <hr />
            </div>
            <button
              class="btn btn-primary text-center"
              @click="getCalculationJudgeHTML"
            >
              Хэвлэх
            </button>
          </div>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
import TableFilter from "./TableFilter";
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";
import fs from "fs";
import pdf from "html-pdf";

export default {
  name: "c-table",
  components: {
    axios,
    TableFilter,
    DateRangePicker,
  },
  inheritAttrs: false,
  props: {
    url: { type: String, default: "" },
    caption: { type: String, default: "Table" },
    hover: { type: Boolean, default: false },
    striped: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    fields: { type: [Array, Object], default: () => [] },
    perPage: { type: Number, default: 10 },
    orderBy: { type: String, default: "id" },
    orderDirection: { type: String, default: "ASC" },
    dark: { type: Boolean, default: false },
    columns: { type: [Array, Object], default: () => [] },
    filters: { type: [Array, Object], default: () => [] },
    export: { type: Boolean, default: true },
    filename: { type: String, default: "export" },
    edit_only: { type: Boolean, default: false },
    edit_has: { type: Boolean, default: false },
    edit_route: { type: String, default: "dashboard" },
    edit_params: { type: [Array, Object], default: () => [] },
    delete_has: { type: Boolean, default: false },
    delete_url: { type: String, default: "" },
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    selectSubmitFunction: { type: Function, default: function() {} },
    selectedButton: { type: [Array, Object], default: () => [] },
    searchFields: { type: [Array, Object], default: () => [] },
    redirectToCustomer: { type: Boolean, default: false },
  },
  data: () => {
    return {
      isLoadingData: false,
      isLoading: false,
      filterShow: false,
      query: {
        page: 1,
        per_page: 0,
        orderDirection: "ASC",
        orderBy: "id",
        search: "",
        columns: "",
      },
      pagination: {
        total: 0,
        from: 0,
        to: 0,
      },
      orderDesc: false,
      items: [],
      search: "",
      download: {
        loading: false,
        header: {},
      },
      selected: [],
      selectAll: false,
      filter: {},
      modalData: {
        fb_id: null,
        fb_name: "",
        name: "",
        register: "",
        work: {
          year: "",
          month: "",
          name: "",
        },
      },
      merchants: [],
      selected_merchant: null,
      loan_created_users: [],
      selected_loan_created_user: null,
      judge_list: [],
      selected_judge: null,
      selected_customer: null,
      selected_accredited_judge: null,
      accredited_user: null,
      calculation_position: "Нягтлан бодогч",
      calculation_name: "И.Уринболор",
      isCalcInterestStopped: false,
    };
  },
  created: function() {
    if (!this.isEmpty(this.$route.query)) {
      this.$data.query = this.$route.query;
      this.$data.search = this.$route.query.search;
    } else {
      this.$data.query.columns = this.columns.join(",");
      this.$data.query.per_page = this.perPage;
      this.$data.query.orderDirection = this.orderDirection;
      this.$data.query.orderBy = this.orderBy;
    }
    this.$data.download.header = {};
    for (let i in this.fields) {
      let _field = this.fields[i];
      if (_field.key != "checkbox") {
        if (_field.key == "nicename") {
          this.$data.download.header["Овог"] = "lastname";
          this.$data.download.header["Нэр"] = "firstname";
        } else if (_field.key == "merchantNameWithType") {
          this.$data.download.header["Мерчант"] = "merchantName";
          this.$data.download.header["Зээлийн төрөл"] = "loanTypeName";
        } else {
          if (_field.key != "fbToken")
            this.$data.download.header[_field.label] = _field.key;
        }
      }
    }
    this.loadSearch();
    this.loadJudge();
    // this.loadData();
  },
  computed: {
    captions: function() {
      return this.fields;
    },
  },
  watch: {
    query: {
      handler(query) {
        this.loadData();
      },
      deep: true,
    },
    selectAll: {
      handler(selectAll) {
        this.$data.selected = [];
        if (this.selectAll) {
          for (let i in this.items) {
            this.$data.selected.push(this.items[i]);
          }
        }
      },
      deep: true,
    },
    startDate: {
      handler(startDate) {
        this.$data.query.page = 1;
        this.loadData();
      },
      deep: true,
    },
    endDate: {
      handler(endDate) {
        this.$data.query.page = 1;
        this.loadData();
      },
      deep: true,
    },
    selected_merchant: {
      handler(selected_merchant) {
        if (selected_merchant)
          this.$data.query.merchant_id = selected_merchant.id;
        else this.$data.query.merchant_id = null;
        this.$data.query.page = 1;
        this.loadData();
      },
      deep: true,
    },
    selected_loan_created_user: {
      handler(selected_loan_created_user) {
        if (selected_loan_created_user)
          this.$data.query.sys_user_id = selected_loan_created_user.id;
        else this.$data.query.sys_user_id = null;
        this.$data.query.page = 1;
        this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    loadJudge: function() {
      this.$http
        .get(this.$config.API_URL + "StatementWebService/get_judge_list", {
          params: {},
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response);
            this.isLoading = false;
            this.$data.judge_list = response.body.data;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    loadData: function() {
      this.checkSession();
      this.$data.selected = [];
      this.$data.selectAll = false;
      if (this.startDate != null) this.$data.query.start_at = this.startDate;
      if (this.endDate != null) this.$data.query.end_at = this.endDate;
      this.isLoadingData = true;
      this.$http
        .get(this.$config.API_URL + this.url, {
          params: this.$data.query,
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response);
            this.isLoadingData = false;
            this.$data.items = response.body.data;
            this.$data.pagination.total = response.body.total;
            this.$data.pagination.from = response.body.from;
            this.$data.pagination.to = response.body.to;
          },
          (response) => {
            this.isLoadingData = false;
          }
        );
      this.$router.push({ name: this.$route.name, query: this.$data.query });
    },
    sortingChanged(ctx) {
      let _field = "";
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },
    async fetchData() {
      let _q = {
        page: 1,
        per_page: 40000,
        orderDirection: this.$data.query.orderDirection,
        orderBy: this.$data.query.orderBy,
        search: this.$data.query.search,
        columns: this.$data.query.columns,
      };
      if (this.startDate != null) _q.start_at = this.startDate;
      if (this.endDate != null) _q.end_at = this.endDate;
      if (this.download.loading) {
        this.download.loading = true;
        const response = await axios.get(this.$config.API_URL + this.url, {
          params: _q,
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        });
        if (response.data.status == 500) {
          this.showToast(
            "Алдаа",
            "Алдаа гарсан байна. Дахин оролдоно уу",
            "danger"
          );
          this.download.loading = false;
          return [];
        }
        let _result = [];
        for (let i in response.data.data) {
          let _item = response.data.data[i];
          _item.index = parseInt(i) + 1;
          if (_item.hasOwnProperty("loanTypeCodeList")) {
            var s = [];
            for (var j in _item.loanTypeCodeList) {
              s.push(this.this.$store.getters.loan_type[_item.loanTypeCodeList[j]]);
            }
            _item.loanTypeCodeList = s.join(", ");
          }
          _result.push(_item);
        }
        return _result;
      }
      return [];
    },
    startDownload() {
      this.download.loading = true;
    },
    finishDownload() {
      this.download.loading = false;
    },
    remove(_id) {
      this.$bvModal
        .msgBoxConfirm("Устгахдаа итгэлтэй байна уу?", {
          title: "",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Тийм",
          cancelTitle: "Үгүй",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            let deleteData = {
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              id: _id,
            };

            this.$http
              .post(
                this.$config.API_URL + this.delete_url,
                { data: JSON.stringify(deleteData) },
                { headers: this.$store.getters.httpHeader, emulateJSON: true }
              )
              .then(
                (response) => {
                  this.showToast("Амжилттай", "Амжилттай устгалаа", "success");
                  this.isLoading = false;
                  this.loadData();
                },
                (response) => {
                  this.isLoading = false;
                }
              );
          }
        })
        .catch((err) => {
          this.showToast("Алдаа", err, "danger");
        });
    },
    selectedFunction: function() {
      if (this.$data.selected.length > 0)
        this.selectSubmitFunction(this.$data.selected);
      else this.showToast("Анхаар", "Зээл сонгоогүй байна", "warning");
    },
    dateRange: function() {
      return this.$config.DATERANGE;
    },
    toRoute: function(param) {
      this.$router.push(param);
    },
    submitSearch: function() {
      // var _filters = {}
      // for(var i in this.filters) {
      //   let val = this.filters[i].value
      //   if(this.filters[i].filter_type == 'date') {
      //     val = {
      //       startDate: this.filters[i].value.startDate.format('YYYY-MM-DD'),
      //       endDate: this.filters[i].value.endDate.format('YYYY-MM-DD')
      //     }
      //   }
      //   _filters[this.filters[i].key] = val
      // }
      // this.$data.query.filter = JSON.stringify(_filters)
      this.$data.query.search = this.$data.search;
      this.$data.page = 1;
    },
    getLastWorkData: function(item) {
      this.isLoading = true;
      this.$data.modalData.work.name = "";
      this.$data.modalData.work.year = "";
      this.$data.modalData.work.month = "";
      this.$data.modalData.nicename =
        item.lastname.substr(0, 1) + "." + item.firstname;
      this.$data.modalData.phone = item.phoneNumber;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_worker_last_position",
          {
            params: { id: item.customerId },
            emulateJSON: true,
            headers: this.$store.getters.httpHeader,
          }
        )
        .then(
          (response) => {
            //console.log(response);
            if (response.body.responseResultType == "SUCCESS") {
              this.$data.modalData.work.name = response.body.workName;
              this.$data.modalData.work.year = response.body.year;
              this.$data.modalData.work.month = response.body.month;
            }
            this.getFbData(item);
          },
          (response) => {
            this.isLoading = false;
            alert("Мэдээлэл байхгүй байна");
          }
        );
    },
    getFbData: function(item) {
      this.isLoading = true;
      this.$data.modalData.fb_id = "";
      this.$data.modalData.fb_name = "";
      this.$http
        .get(this.$config.FB_GRAPH_URL + item.fbToken, {
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.isLoading = false;
            this.$data.modalData.fb_id = response.body.id;
            this.$data.modalData.fb_name = response.body.name;
            this.$refs["fb-modal"].show();
          },
          (response) => {
            this.isLoading = false;
            this.showToast(
              "Анхаар",
              "FB мэдээлэл харуулах боломжгүй байна",
              "warning"
            );
            if (this.$data.modalData.work.name != "") {
              this.$refs["fb-modal"].show();
            }
          }
        );
    },
    getOverdueJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/overdue_judge", {
          params: { id: item.customerId },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            // mywindow.document.close();

            setTimeout(function() {
              mywindow.print();
              mywindow.close();
            }, 500);

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getOrderJudgeModal: function(item) {
      this.$data.selected_customer = item.customerId;
      this.$refs["judge-modal"].show();
    },
    getOrderJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/order_judge", {
          params: {
            id: this.$data.selected_customer,
            lid: this.$data.selected_judge,
          },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            setTimeout(function() {
              mywindow.print();
              mywindow.close();
            }, 500);
            // mywindow.document.close();
            // mywindow.open("");

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getAccreditedJudgeModal: function(item) {
      this.$data.selected_customer = item.customerId;
      this.$refs["judge-accredited-modal"].show();
    },
    getCalculationJudgeModal: function(item) {
      this.$data.selected_customer = item.customerId;
      this.$refs["judge-calculation-modal"].show();
    },
    getAccreditedJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/accredited_judge", {
          params: {
            id: this.$data.selected_customer,
            lid: this.$data.selected_accredited_judge,
            accredited_user: this.$data.accredited_user,
          },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            setTimeout(function() {
              mywindow.print();
              mywindow.close();
            }, 500);
            // mywindow.document.close();
            // mywindow.open("");

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    getCalculationJudgeHTML: function(item) {
      this.isLoading = true;
      this.$http
        .get(this.$config.API_URL + "StatementWebService/calculcation_html", {
          params: {
            id: this.$data.selected_customer,
            position: this.$data.calculation_position,
            name: this.$data.calculation_name,
          },
          emulateJSON: true,
          headers: this.$store.getters.httpHeader,
        })
        .then(
          (response) => {
            this.isLoading = false;
            var mywindow = window.open("", "Print", "_blank");
            mywindow.document.write(response.body.successData);
            setTimeout(function() {
              mywindow.print();
              mywindow.close();
            }, 500);
            // mywindow.open("");

            //console.log(response);
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    checkSearchFields: function(field) {
      return this.searchFields.includes(field);
    },
    loadSearch: function() {
      if (this.checkSearchFields("merchant")) {
        this.$http
          .get(this.$config.API_URL + "PurchaseWebService/get_merchant_list", {
            params: {
              page: 1,
              per_page: 0,
              orderBy: "code",
              columns: [
                "code",
                "name",
                "register_number",
                "start_date",
                "end_date",
                "create_date",
              ].join(","),
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          })
          .then(
            (response) => {
              //console.log(response);
              this.isLoading = false;
              this.$data.merchants = response.body.data;

              if (!this.isEmpty(this.$route.query)) {
                for (var i in this.$data.merchants) {
                  if (
                    this.$data.merchants[i].id == this.$route.query.merchant_id
                  ) {
                    this.$data.selected_merchant = this.$data.merchants[i];
                  }
                }
              }
            },
            (response) => {
              this.isLoading = false;
            }
          );
      }
      if (this.checkSearchFields("loanCreatedUser")) {
        this.$http
          .get(
            this.$config.API_URL + "AdminWebService/get_loan_employee_list",
            {
              headers: this.$store.getters.httpHeader,
              emulateJSON: true,
            }
          )
          .then(
            (response) => {
              this.$data.loan_created_users = response.body.data;
            },
            (response) => {}
          );
      }
    },
  },
};
</script>
