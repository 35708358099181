<template>
  <div class="pull-right">
    <b-button variant="primary" @click="showModal">Шинээр үүсгэх</b-button>

    <b-modal ref="my-modal" title="Тэмдэглэл" hide-footer>
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity"
        :background-color="this.$config.LOADER.backgroundColor"
        :z-index="10000"
      ></loading>
      <b-form @submit.stop.prevent="save">
        <b-form-group label="Төрөл">
          <treeselect
            v-model="selected_type"
            :disable-branch-nodes="true"
            :show-count="true"
            :options="note_types"
          />
          <!-- <b-form-radio v-model="selected_type" :key="index" v-for="(item, index) in subParent.children" :name="subParent.name" :value="item">{{ item.name }}</b-form-radio> -->
        </b-form-group>

        <b-form-group label="" label-for="input-body">
          <b-form-textarea
            v-model="form.body"
            placeholder="Нэмэлт тайлбар"
          ></b-form-textarea>
        </b-form-group>

        <hr />
        <b-form-checkbox
          v-model="form.connection_count"
          value="2"
          unchecked-value="1"
        >
          2дах холболтоор шийдвэрлэгдсэн дуудлага
        </b-form-checkbox>

        <b-form-checkbox
          v-model="form.is_missed_call"
          :value="true"
          :unchecked-value="false"
        >
          Алдсан дуудлага
        </b-form-checkbox>
        <hr />
        <b-form-checkbox-group v-model="passion">
          <b-form-checkbox :value="true">Төлөх эрмэлзэлтэй</b-form-checkbox>
          <b-form-checkbox :value="false">Төлөх эрмэлзэлгүй</b-form-checkbox>
        </b-form-checkbox-group>
        <hr />
        <b-form-radio-group v-model="form.is_mobile">
          <b-form-radio :value="false">Суурин утас</b-form-radio>
          <b-form-radio :value="true">Гар утас</b-form-radio>
        </b-form-radio-group>
        <hr />
        <b-form-radio-group v-model="outbound">
          <b-form-radio :value="false">Inbound</b-form-radio>
          <b-form-radio :value="true">Outbound</b-form-radio>
        </b-form-radio-group>
        <hr />
        <b-form-checkbox v-model="form.is_remaining">Эргэн холбогдох эсэх</b-form-checkbox>
        <div v-if="form.is_remaining">
          <b-form-group 
            class="mt-3"
            id="input-group-certDate"
            label="Эргэн холбогдох огноо"
            label-for="input-certDate"
          >
            <b-datepicker v-model="form.remain_date"
            placeholder="Сонгоно уу"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              autocomplete="off"></b-datepicker>
          </b-form-group>
          <hr />
        </div>
        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Customer.NoteCreate",
  props: ["customerId", "customer", "callback"],
  components: { Treeselect },
  data: function() {
    return {
      isLoading: false,
      parent: null,
      subParent: null,
      form: {
        id: this.customerId,
        type: null,
        body: "",
        connection_count: 1,
        is_missed_call: false,
        is_payment_passion: null,
        is_mobile: false,
        category: "",
        remain_date: null,
        is_remaining: false
      },
      custom_type: false,
      selected_type: null,
      note_types: [],
      passion: [],
      outbound: false,
    };
  },
  watch: {
    outbound: {
      handler(outbound) {
        if (outbound) {
          this.$data.form.category = "Outbound";
        } else {
          this.$data.form.category = "Inbound";
        }
      },
      deep: true,
    },
    customerId: {
      handler(customerId) {
        this.$data.form.id = this.customerId;
      },
      deep: true,
    },
    passion: {
      handler(passion) {
        if (passion.length > 1) {
          passion.shift();
        }
        if (passion.length > 0) {
          this.$data.form.is_payment_passion = passion[0];
        } else {
          this.$data.form.is_payment_passion = null;
        }
      },
      deep: true,
    },
    selected_type: {
      handler(selected_type) {
        // //console.log(selected_type);
        // this.$data.form.body = (selected_type.isOther)?'':selected_type.name
        this.$data.form.type = selected_type;
      },
      deep: true,
    },
  },
  created() {
    this.getLoadTypes();
  },
  methods: {
    getLoadTypes: function() {
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_note_type_list", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            // //console.log(response);
            if (response.status == 200) {
              this.$data.note_types = response.body.data;
              // for(var i in response.body.data) {
              //     this.$data.note_types.push({
              //         value: response.body.data[i],
              //         text: response.body.data[i].name
              //     })
              // }
            }
          },
          (response) => {
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    save: function() {
      if (this.$data.form.type == null) {
        this.showToast("Анхаар", "Төрөл сонгоогүй байна", "warning");
      } else {
        if(this.$data.form.is_remaining && this.$data.form.remain_date == null) {
          this.showToast("Анхаар", "Эргэн холбогдох өдөр сонгоогүй байна", "warning");
        } else {
          this.isLoading = true;
        (this.$data.form.token = this.$store.getters.token),
          (this.$data.form.email = this.$store.getters.email),
          this.$http
            .post(
              this.$config.API_URL + "CustomerWebService/create_note",
              { data: JSON.stringify(this.$data.form) },
              { headers: this.$store.getters.httpHeader, emulateJSON: true }
            )
            .then(
              (response) => {
                this.isLoading = false;
                if (response.body.responseResultType == "SUCCESS") {
                  this.showToast(
                    "Амжилттай",
                    "Амжилттай хадгалагдлаа",
                    "success"
                  );
                  this.hideModal();
                  this.callback();
                } else {
                  let _ms = response.body.failureMessages.message;
                  for (var i in _ms) {
                    this.showToast("Анхаар", _ms[i].failureMessage, "warning");
                  }
                }
              },
              (response) => {
                this.isLoading = false;
                this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
              }
            );
        }
      }
    },
    showModal() {
      this.$data.form = {
        id: this.customerId,
        type: null,
        body: "",
        connection_count: 1,
        is_missed_call: false,
        is_payment_passion: null,
        is_mobile: false,
        remain_date: null,
        is_remaining: false
      };
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
